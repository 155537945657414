import React, { useState } from "react";
import { Input, Form, message, Typography, Button } from "antd";
import { PhoneOutlined } from "@ant-design/icons";

import BaseModal from "../../../common/base-modal";
import Spinner from "../../../common/spinner";

import API from "../../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../../utils/helper.functions";

import Alert from "../../../../images/alert.png";

import "./styles.scss";

const { Title } = Typography;

const ForgotPasswordModal = ({
  showModal,
  onClose,
  setShowVerificationModal,
  setUserName,
}) => {
  const auth = new API.Auth();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    const payload = {
      username: values?.username,
    };
    setUserName(values?.username);

    getDataManager(auth?.sendVerificationCode, setLoading, payload).then(
      (x) => {
        if (x?.status === "success") {
          onClose();
          setShowVerificationModal(true);
        } else {
          const error = getErrorMessage(x?.errors);
          message.error({
            content: x?.message || error || "Error ocurred while sending code",
            duration: 3,
          });
        }
      }
    );
  };

  return (
    <>
      <BaseModal showModal={showModal} closeModal={onClose}>
        {loading && <Spinner title="Sending verification code..." />}
        <section className="tajira-forgot-modal">
          <div className="forgot-password-header">
            <img src={Alert} alt="" width={60} height={60} />
            <br />
            <Title level={4}>Forgot your password?</Title>
          </div>
          <br />
          <Title level={5} className="forget-password-description">
            Enter the Phone number associated with your account. We will send
            you a OTP code to verify that it's you!
          </Title>
          <br />
          <div className="login-modal-left-section">
            <Form form={form} layout="vertical" onFinish={onSubmit}>
              <Form.Item
                name="username"
                label="Mobile Number"
                rules={[
                  { required: true, message: " Please enter mobile number" },
                ]}
              >
                <Input
                  placeholder="Enter your mobile number"
                  size="large"
                  addonBefore={<PhoneOutlined />}
                />
              </Form.Item>
              <Form.Item>
                <Button block htmlType="submit" type="primary" size="large">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </section>
      </BaseModal>
    </>
  );
};

export default ForgotPasswordModal;
