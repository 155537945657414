import React from "react";
import { Badge, Space } from "antd";
import {
  ShoppingCartOutlined,
  MenuOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import GlobalSearch from "../../header/mini-components/global-search";

import comanyLogo from "../../../images/companyLogo.PNG";

import { setShowLoginModal } from "../../../utils/redux/actions/actions";

import "./styles.scss";

const Header = (props) => {
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.app);

  return (
    <header className="mobile-header-base-container">
      <section className="top-section">
        <div className="logo-container">
          <MenuOutlined
            className="menu-icon"
            onClick={props.showMenuSideDrawer}
          />
          <Logo />
        </div>
        <Space size={10}>
          {!isLoggedIn && (
            <div
              className="login-btn"
              onClick={() => dispatch(setShowLoginModal(true))}
            >
              Login
            </div>
          )}
          <Cart />
          <WishList />
          &nbsp;&nbsp;
        </Space>
      </section>
      <section className="middle-section">
        <GlobalSearch />
      </section>
    </header>
  );
};

export default Header;

const Logo = () => {
  return (
    <Link to="/">
      <img className="company-logo" src={comanyLogo} alt="logo" />
    </Link>
  );
};

const WishList = () => {
  const { wishlistItems } = useSelector((state) => state.app);

  let quantity = 0;

  (wishlistItems || []).forEach((w) => {
    quantity = quantity + w?.products?.length;
  });

  return (
    <Link className="wishlist" to="/account/wishlist">
      <Badge count={quantity} showZero={true}>
        <HeartOutlined />
      </Badge>
    </Link>
  );
};

const Cart = () => {
  const { cartItems } = useSelector((state) => state.app);

  let quantity = 0;

  (cartItems || []).forEach((w) => {
    quantity = quantity + w?.products?.length;
  });

  return (
    <div className="cart">
      <Link to="/shopping-cart">
        <Badge count={quantity} showZero={true}>
          <ShoppingCartOutlined />
        </Badge>
      </Link>
    </div>
  );
};
