/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Input, Form, message, Button, Select, Row, Col } from "antd";

import {
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isNumberRegex } from "../../../../utils/helper.functions";

import BaseModal from "../../../common/base-modal";

import {
  setIsLoggedIn,
  setUserData,
} from "../../../../utils/redux/actions/actions";
import CodeVerificationModal from "../code-verification";
import AccountCreated from "../account-created";
import RegistrationPending from "../registration-pending";
import ForgotPasswordModal from "../forgot-password";

import API from "../../../../utils/api";
import {
  getDataManager,
  getErrorMessage,
} from "../../../../utils/helper.functions";
import { useWindowSize } from "../../../../utils/custom-hooks";

import "./styles.scss";

const Login = ({ showLogin, onClose, showModal, closeModal }) => {
  const auth = new API.Auth();
  const country = new API.Country();
  const [form] = Form.useForm();

  const { Option } = Select;

  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showAccountCreatedModal, setShowAccountCreatedModal] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [countryCode, setCountryCode] = useState("+880");
  const [showRegistrationPending, setShowRegistrationPending] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [statusInfo, setStatusInfo] = useState("individual");
  const [isRegistrationPending, setIsRegistrationPending] = useState(false);
  const [userName, setUserName] = useState("");

  const history = useHistory();

  const size = useWindowSize();

  useEffect(() => {
    fetchCountryList();
  }, []);

  const handleRefresh = () => {
    window.location.reload(false);
  };

  var sortedCountryCodes = countryList.sort((a, b) =>
    a.phone_code.localeCompare(b.phone_code)
  );

  const fetchCountryList = () => {
    getDataManager(country?.getCountries).then((x) => {
      if (x?.status === "success") {
        setCountryList(x?.data?.results);
      } else {
        message.error({
          content: "Error ocurred while fetching countries",
        });
      }
    });
  };

  const onSubmit = (values) => {
    const payload = {
      username: countryCode + values?.username,
      password: values?.password,
    };

    message.loading({
      content: "Signing in...",
      duration: 0,
      key: "loader",
    });
    handleSignIn(payload);
  };

  const handleSignIn = (payload) => {
    getDataManager(auth?.loginUser, null, payload).then((x) => {
      if (x?.status === "success") {
        if (x?.data?.token) {
          message.success({
            content: "You have successfully logged in",
            duration: 3,
            key: "loader",
          });
          localStorage.setItem(
            "tajira-website-logged-in-user-data",
            JSON.stringify(x?.data)
          );
          dispatch(setUserData(x?.data));
          dispatch(setIsLoggedIn(true));
          onClose();
          history.push("/");
          handleRefresh();
        } else {
          if (x?.data?.show_popup) {
            message.info({
              content: "Please reset your password to login",
              duration: 3,
              key: "loader",
            });
          } else {
            message.warning({
              content:
                "Your account is not verified! Please verify it to proceed.",
              key: "loader",
            });
            setIsRegistrationPending(true);
            setEmail(x?.data?.email);
            setStatusInfo(x?.data?.is_company ? "company" : "individual");
            resendVerificationCode();
            setShowVerificationModal(true);
            onClose();
          }
        }
      } else {
        const error = getErrorMessage(x?.errors);
        dispatch(setIsLoggedIn(false));
        message.error({
          content: x?.message || error || "Error ocurred while logging in",
          duration: 3,
          key: "loader",
        });
      }
    });
  };

  const resendVerificationCode = () => {
    const auth = new API.Auth();

    const payload = {
      email: email,
    };

    getDataManager(auth?.resendVerificationCode, null, payload).then((x) => {
      if (x?.status === "success") {
        message.success({
          content: x?.message || "Verification code has been sent",
          duration: 3,
        });
      } else {
        message.error({
          content: x?.message[0] || "Error ocurred in signing up",
          duration: 3,
        });
      }
    });
  };

  const onCloseAccountCreateModal = () => {
    setShowAccountCreatedModal(false);
    if (statusInfo !== "individual") {
      setShowRegistrationPending(true);
    } else {
      history.push("/");
    }
  };

  const onCloseRegistrationModal = () => {
    setShowRegistrationPending(false);
    history.push("/");
  };

  return (
    <>
      <BaseModal type2={true} showModal={showLogin} closeModal={onClose}>
        <section className="tajira-login-modal">
          <div className="login-modal-left-section">
            <Form form={form} layout="vertical" onFinish={onSubmit}>
              <header className="header">Please Login to Continue</header>
              <Form.Item
                label="Mobile Number"
                name="username"
                rules={[
                  {
                    pattern: isNumberRegex,
                    message: "Please enter numbers only",
                  },
                  // {
                  //   transform: (value) => "+880" + value,
                  //   pattern: uaePhoneRegex,
                  //   message: "Please enter valid UAE number",
                  // },
                  { required: true, message: "Please enter phone number" },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Please enter mobile number"
                  addonBefore={
                    <Select
                      showSearch
                      bordered={false}
                      style={{ width: 120 }}
                      onChange={(value) => setCountryCode(value)}
                      defaultValue={"+880"}
                    >
                      {(sortedCountryCodes || []).map((c) => (
                        <Option key={c?.id} value={c?.phone_code}>
                          <Row gutter={[6, 6]}>
                            <Col span={14}>{c?.phone_code}</Col>
                            <Col span={10}>
                              <img src={c?.logo} alt="" width={20} />
                            </Col>
                          </Row>
                        </Option>
                      ))}
                    </Select>
                  }
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter password",
                  },
                ]}
              >
                <Input.Password
                  addonBefore={<LockOutlined />}
                  placeholder="Please enter Password"
                  visible={true}
                  onCopy={() => ""}
                  visibilityToggle={false}
                  onPaste={() => ""}
                  size="large"
                  iconRender={(visible) =>
                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                  }
                />
              </Form.Item>

              <div className="captcha"></div>
              <div
                className="forgot-password"
                onClick={() => setShowForgotPassword(true)}
              >
                Forgot your password?
              </div>
              <Form.Item>
                <Button type="primary" block htmlType="submit">
                  Login
                </Button>
              </Form.Item>
            </Form>
            {size.width < 801 && (
              <>
                <header className="heading">Are you a new user in</header>
                <div className="company-title">Tajira.com?</div>
                <br />
                <Button
                  className="tajira-base-button outlined"
                  block
                  onClick={() => {
                    onClose();
                    history.push("/register");
                  }}
                >
                  Create an Account
                </Button>
              </>
            )}
          </div>

          {size.width > 800 && (
            <div className="login-modal-right-section">
              <header className="heading">Are you a new user in</header>
              <div className="company-title">Tajira.com?</div>
              <div className="its-easy">Welcome to the Tajira!</div>
              <div className="description">
                By creating an account you will be able to shop faster, be up to
                date on an order's status, and keep track of the orders you have
                previously made.
              </div>
              <Button
                block
                className="tajira-base-button outlined"
                onClick={() => {
                  onClose();
                  history.push("/register");
                }}
              >
                Sign Up Now!
              </Button>
            </div>
          )}
        </section>
      </BaseModal>
      <ForgotPasswordModal
        showModal={showForgotPassword}
        onClose={() => setShowForgotPassword(!showForgotPassword)}
        setShowVerificationModal={(s) => setShowVerificationModal(s)}
        setUserName={setUserName}
      />
      <CodeVerificationModal
        showModal={showVerificationModal}
        closeModal={() => setShowVerificationModal(false)}
        userName={userName}
      />
      <AccountCreated
        showModal={showAccountCreatedModal}
        closeModal={onCloseAccountCreateModal}
      />
      <RegistrationPending
        showModal={showRegistrationPending}
        closeModal={onCloseRegistrationModal}
      />
    </>
  );
};

export default Login;
