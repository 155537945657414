import React from "react";
import { Select, Space } from "antd";
import { useSelector } from "react-redux";

import { PhoneFilled } from "@ant-design/icons";

import uk from "../../../../images/uk.png";
import money from "../../../../images/money.png";

import "./styles.scss";

const selectStyle = {
  color: "#fff",
  fontSize: "11pt",
  display: "flex",
  alignItems: "center",
  width: "150px",
};

const TopMenuBar = () => {
  const { infoPage } = useSelector((state) => {
    return state?.app;
  });

  return (
    <div className="top-menu-bar-base-container">
      <Space size={50}>
        Welcome to Tajira!
        <ContactNumber infoPage={infoPage} />
      </Space>
      <section>
        <Select
          style={selectStyle}
          defaultValue={
            <div style={selectStyle}>
              <img src={uk} alt="uk" /> English
            </div>
          }
          bordered={false}
        ></Select>
        <Select
          style={selectStyle}
          defaultValue={
            <div style={selectStyle}>
              <img src={money} alt="uk" /> AED
            </div>
          }
          bordered={false}
        ></Select>
      </section>
    </div>
  );
};

export default TopMenuBar;

const ContactNumber = ({ infoPage }) => {
  return (
    <a href={`tel:${infoPage?.phone}`}>
      <PhoneFilled /> 24/ 7 Support on {infoPage?.phone}
    </a>
  );
};
