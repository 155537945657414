/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Drawer, Collapse, Space } from "antd";
import { useSelector } from "react-redux";
import {
  HomeFilled,
  CloseOutlined,
  PlusSquareOutlined,
  MinusSquareOutlined,
  MailOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  BellOutlined,
  ShopOutlined,
  PaperClipOutlined,
  MessageOutlined,
} from "@ant-design/icons";

import { useHistory, Link } from "react-router-dom";

import "./styles.scss";

const { Panel } = Collapse;

const MenuSideBar = ({ showSidebar, onCloseSidebar }) => {
  const history = useHistory();

  const { brands, categories } = useSelector((state) => {
    return state?.app;
  });

  const Categories = ({ categories }) => {
    return (
      <Space size={15} direction="vertical" style={{ paddingLeft: 50 }}>
        {categories?.map((b) => {
          return b?.children.length > 0 ? (
            <Collapse bordered={false} style={{marginLeft:"-55px"}}>
              <Panel
                header={
                  <Link
                    key={b?.id}
                    to={`/category/${b.id}`}
                    onClick={handleSelectOption}
                  >
                    {b?.name}
                  </Link>
                }
                key="1"
              >
                <Space
                  size={15}
                  direction="vertical"
                  style={{ paddingLeft: 50 }}
                >
                  {b?.children?.map((ch) => {
                    return (
                      <Link
                        key={ch?.id}
                        to={`/category/${ch.id}`}
                        onClick={handleSelectOption}
                      >
                        {ch?.name}
                      </Link>
                    );
                  })}
                </Space>
              </Panel>
            </Collapse>
          ) : (
            <Link
              key={b?.id}
              to={`/category/${b.id}`}
              onClick={handleSelectOption}
            >
              {b?.name}
            </Link>
          );
        })}
      </Space>
    );
  };

  const Brands = ({ brands }) => {
    return (
      <Space size={15} direction="vertical" style={{ paddingLeft: 50 }}>
        {brands?.map((b) => (
          <Link key={b?.id} to={`/brand/${b.id}`} onClick={handleSelectOption}>
            {b?.name}
          </Link>
        ))}
      </Space>
    );
  };

  const handleSelectOption = (path) => {
    onCloseSidebar();
    path && history.push(path);
  };

  return (
    <Drawer
      placement="left"
      title={<Title onCloseSidebar={onCloseSidebar} />}
      closable={false}
      onClose={onCloseSidebar}
      visible={showSidebar}
      width={"80%"}
    >
      <Collapse
        accordion
        bordered={false}
        expandIconPosition={"right"}
        expandIcon={({ isActive }) =>
          isActive ? <MinusSquareOutlined /> : <PlusSquareOutlined />
        }
        className="drawer-options-collapse"
      >
        <div className="home-option" onClick={() => handleSelectOption("/")}>
          <HomeFilled style={{ marginRight: "10px" }} /> Home
        </div>
        <div>
          <Collapse>
            <Panel header=" Categories" key="1">
              <Categories categories={categories} />
            </Panel>
          </Collapse>
        </div>
        <div>
          <Collapse>
            <Panel header=" Brands" key="2">
              <Brands brands={brands} />
            </Panel>
          </Collapse>
        </div>

        <div
          className="home-option"
          onClick={() => handleSelectOption("/all-shops")}
        >
          <ShopOutlined style={{ marginRight: "10px" }} />
          Shops
        </div>
        <div
          className="home-option"
          onClick={() => handleSelectOption("/blogs")}
        >
          <PaperClipOutlined style={{ marginRight: "10px" }} />
          Blogs
        </div>
        <div
          className="home-option"
          onClick={() => handleSelectOption("/campaigns")}
        >
          <BellOutlined style={{ marginRight: "10px" }} />
          Campaigns
        </div>
        <div
          className="home-option"
          onClick={() => handleSelectOption("/all-messages")}
        >
          <MessageOutlined style={{ marginRight: "10px" }} />
          Messages
        </div>
        <div
          className="home-option"
          onClick={() => handleSelectOption("/contact-us")}
        >
          <MailOutlined style={{ marginRight: "10px" }} />
          Contact Us
        </div>
        <div className="home-option" onClick={onCloseSidebar}>
          <ExclamationCircleOutlined style={{ marginRight: "10px" }} />
          About Us
        </div>
      </Collapse>
    </Drawer>
  );
};

export default MenuSideBar;

const Title = ({ onCloseSidebar }) => {
  const history = useHistory();
  const userData = JSON.parse(
    localStorage.getItem("tajira-website-logged-in-user-data")
  );

  return (
    <div className="menu-sidebar-title">
      <div
        onClick={() => {
          onCloseSidebar();
          history.push("/account/my-account");
        }}
      >
        <UserOutlined />{" "}
        <span className="my-account-text">
          {userData
            ? `${userData?.first_name} ${userData?.last_name}`
            : "My Account"}
        </span>
      </div>
      <CloseOutlined className="drawer-close-icon" onClick={onCloseSidebar} />
    </div>
  );
};
