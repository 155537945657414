import axios from "axios";
import { API_URL } from "../constants";

export default class Brand {
  getBrandList = (paylaod = {}) => {
    return axios
      .get(
        `${API_URL}api/v1/store/public/brands/?limit=${
          paylaod?.limit || 50
        }&offset=${paylaod?.offset || 0}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        console.log(error);
        return error?.response?.data?.message;
      });
  };
}
