import axios from "axios";
import { API_URL, TOKEN } from "../constants";

export default class Subscriptions {
  getSubscriptionsList = () => {
    return axios
      .get(`${API_URL}api/v1/account/public/membership-package/`, {
        headers: {
          Authorization: `Token ${
            (
              JSON.parse(
                localStorage.getItem("tajira-website-logged-in-user-data")
              ) || {}
            ).token
          }`,
        },
      })
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  changeSubscription = (payload = {}) => {
    return axios
      .post(
        `${API_URL}api/v1/account/public/membership-plan/subscribe/`,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };

  walletRecharge = (payload = {}) => {
    return axios
      .post(
        `${API_URL}api/v1/account/public/wallet/recharge/`,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${TOKEN}`,
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch(function (error) {
        return error?.response?.data;
      });
  };
}
