/* eslint-disable no-useless-concat */
import React from "react";
import { Row, Col, Card } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "./styles.scss";

const SubOptions = () => {
  const { brands } = useSelector((state) => state.app);

  return (
    <div
      id="brands-cascaded-options-base-container"
      className="brands-cascaded-options-base-container"
    >
      <Card bordered={false}>
        <Row mode="vertical" gutter={[0, 20]}>
          {brands?.map((c) => (
            <Col key={c?.id} span={6}>
              <Link to={`/brand/${c?.id}`}>{c?.name}</Link>
            </Col>
          ))}
        </Row>
      </Card>
    </div>
  );
};

export default SubOptions;
