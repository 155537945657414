import axios from "axios";
import { API_URL, TOKEN } from "../constants";

export default class Bank {
  addBank = (payload) => {
    const url = `${API_URL}api/v1/accounting/bank/`;
    return axios
      .post(
        url,
        { ...payload },
        {
          headers: {
            Authorization: `Token ${TOKEN}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          return response.data;
        } else {
          return {
            data: {
              results: [],
            },
          };
        }
      })
      .catch((error) => {
        console.log(error);
        return error?.response?.data?.message;
      });
  };
}
