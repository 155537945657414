/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Suspense } from "react";
import { message } from "antd";
import { Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";

import Spinner from "../common/spinner";

import API from "../../utils/api";
import { getDataManager } from "../../utils/helper.functions";
import { setInfoPage } from "../../utils/redux/actions/actions";

import "./styles.scss";

const ProductsGallery = React.lazy(() => import("./products-gallery"));
const SearchResults = React.lazy(() => import("./search-results"));
const ShopHome = React.lazy(() => import("./shop-home"));
const ShoppingCart = React.lazy(() => import("./shopping-cart"));
const ProductFullDetails = React.lazy(() => import("./product-full-detail"));
const Checkout = React.lazy(() => import("./checkout"));
const Account = React.lazy(() => import("./account"));
const Register = React.lazy(() => import("./register"));
const OrderConfirm = React.lazy(() => import("./order-confirm"));
const ContactUs = React.lazy(() => import("./contact-us"));
const Brands = React.lazy(() => import("./all-brands"));
const AboutUs = React.lazy(() => import("./about-us"));
const TermsAndConditions = React.lazy(() => import("./terms-and-conditions"));
const TrackOrder = React.lazy(() => import("./account/track-order"));
const AllShops = React.lazy(() => import("./all-shops"));
const Blogs = React.lazy(() => import("./tajira-blogs"));
const AllComments = React.lazy(() => import("./tajira-blogs/all-comments"));
const Campaigns = React.lazy(() => import("./campaign"));
const FindSimilar = React.lazy(() => import("./find-similar"));
const Messages = React.lazy(() => import("../home/messages"));

const Home = () => {
  const pages = new API.Pages();

  const dispatch = useDispatch();

  useEffect(() => {
    fetchPagesInfo();
  }, []);

  const fetchPagesInfo = () => {
    getDataManager(pages?.infoPage, null).then((x) => {
      if (x?.status === "success") {
        dispatch(setInfoPage(x?.data));
      } else {
        message.error({
          content: "Error ocurred while loading info",
        });
      }
    });
  };

  return (
    <div className="tajira-home-base-component">
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/" component={ProductsGallery} />
          <Route
            exact
            path="/product/:productId"
            component={ProductFullDetails}
          />
          <Route exact path="/shop/:shopId" component={ShopHome} />
          <Route exact path="/search-results" component={SearchResults} />
          <Route exact path="/category/:categoryId" component={SearchResults} />
          <Route exact path="/special-offer" component={SearchResults} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/shopping-cart" component={ShoppingCart} />
          <Route exact path="/checkout" component={Checkout} />
          <Route exact path="/order-confirm" component={OrderConfirm} />
          <Route exact path="/account/:section" component={Account} />
          <Route exact path="/account/:section/:orderId" component={Account} />
          <Route
            exact
            path="/account/:section/order/:orderId"
            component={Account}
          />
          <Route exact path="/brands" component={Brands} />
          <Route exact path="/brand/:brandId" component={SearchResults} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/all-shops" component={AllShops} />
          <Route exact path="/about-us" component={AboutUs} />
          <Route exact path="/blogs" component={Blogs} />
          <Route exact path="/allcomments" component={AllComments} />
          <Route exact path="/all-messages" component={Messages} />
          <Route exact path="/all-messages/:storeId" component={Messages} />
          <Route exact path="/campaigns" component={Campaigns} />
          <Route exact path="/find-similar/:id" component={FindSimilar} />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndConditions}
          />
          <Route
            exact
            path="/tracking-order/:trackingId"
            component={TrackOrder}
          />
        </Switch>
      </Suspense>
    </div>
  );
};

export default Home;
