/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { message } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Header from "./components/header";
import CommonMenu from "./components/common-menu";
import Home from "./components/home";
import MenuSideBar from "./components/mobile-view-components/menu-sidebar";
import MobileHeader from "./components/mobile-view-components/header";
import Footer from "./components/footer";
import Login from "./components/home/modals/login";
import Spin from "./components/common/spinner";
import Sliders from "./components/home/banner";

import {
  setShowLoginModal,
  setIsLoggedIn,
  setUserData,
  setCartItems,
  setWishlistItems,
  setNewArrivalData,
  setTopSellData,
  setFeaturedData,
  setBrands,
  setCategories,
  setSliders,
} from "./utils/redux/actions/actions";
import API from "./utils/api";

import {
  checkIfSecureRoute,
  getDataManager,
  refreshUserData,
} from "./utils/helper.functions";

import "./App.scss";

const App = () => {
  const home = new API.Home();
  const wishlistAPI = new API.Wishlist();
  const category = new API.Category();
  const brand = new API.Brand();
  const cart = new API.Cart();
  const slider = new API.Slider();

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isHome = location?.pathname === "/";

  const { showLoginModal, isLoggedIn } = useSelector((state) => {
    return state?.app;
  });

  const [showCartDrawer, setShowCartDrawer] = useState(false);
  const [showMenuDrawer, setShowMenuDrawer] = useState(false);
  const [loading, setLoading] = useState(false);

  const userDataStringified = localStorage.getItem(
    "tajira-website-logged-in-user-data"
  );
  const userData = JSON.parse(userDataStringified);
  const isSecure = checkIfSecureRoute(location?.pathname);

  useEffect(() => {
    !!userData?.token && fetchProfileDetails();
    fetchSliderList();
    fetchFeaturedProducts();
    fetchNewlyArrivedProducts();
    fetchTopSaleProducts();
    fetchCategoryList();
    fetchBrandList();
  }, []);

  useEffect(() => {
    dispatch(setUserData(userData));
    dispatch(setIsLoggedIn(!!userData?.token));
    !!userData?.token && fetchCartList();
    !!userData?.token && fetchWishlist();
  }, []);

  useEffect(() => {
    if (!userData?.token && isSecure) {
      history.push("/");
      dispatch(setShowLoginModal(true));
    }

    const element = document.getElementById("base-component");
    element && element.scrollTo(0, 0);
  }, [location?.pathname]);

  const fetchProfileDetails = () => {
    const profile = new API.Profile();

    getDataManager(profile?.getUserProfile, setLoading, userData?.id).then(
      (x) => {
        if (x?.status === "success") {
          const profileData = x?.data;
          const updatedData = {
            ...userData,
            email: profileData?.email,
            first_name: profileData?.first_name,
            last_name: profileData?.last_name,
            mobile: profileData?.mobile,
          };
          dispatch(setUserData(updatedData));
          refreshUserData(updatedData);
        }
      }
    );
  };

  const fetchCategoryList = () => {
    getDataManager(category?.getCategoryList, setLoading).then((x) => {
      if (x?.status === "success") {
        dispatch(setCategories(x?.data));
      } else {
        message.error({
          content: "Error ocurred while loading home items",
        });
        dispatch(setCategories([]));
      }
    });
  };

  const fetchBrandList = () => {
    getDataManager(brand?.getBrandList).then((x) => {
      if (x?.status === "success") {
        dispatch(setBrands(x?.data?.results));
      } else {
        message.error({
          content: "Error ocurred while loading brands",
        });
        dispatch(setBrands([]));
      }
    });
  };

  const fetchSliderList = () => {
    getDataManager(slider?.getSliderList, setLoading).then((x) => {
      if (x?.status === "success") {
        dispatch(setSliders(x?.data?.results));
      } else {
        message.error({
          content: "Error ocurred while loading sliders",
        });
        dispatch(setSliders([]));
      }
    });
  };

  const fetchFeaturedProducts = () => {
    getDataManager(home?.getFeaturedProducts, setLoading).then((x) => {
      if (x?.status === "success") {
        dispatch(setFeaturedData(x?.data?.results));
      } else {
        message.error({
          content: "Error ocurred while loading featured products",
        });
        dispatch(setFeaturedData([]));
      }
    });
  };

  const fetchNewlyArrivedProducts = () => {
    getDataManager(home?.getNewlyArrivedProducts, setLoading).then((x) => {
      if (x?.status === "success") {
        dispatch(setNewArrivalData(x?.data?.results));
      } else {
        message.error({
          content: "Error ocurred while loading newly arrived products",
        });
        dispatch(setNewArrivalData([]));
      }
    });
  };

  const fetchTopSaleProducts = () => {
    getDataManager(home?.getTopSaleProducts, setLoading).then((x) => {
      if (x?.status === "success") {
        dispatch(setTopSellData(x?.data?.results));
      } else {
        message.error({
          content: "Error ocurred while loading top sale data",
        });
        dispatch(setTopSellData([]));
      }
    });
  };

  const fetchCartList = () => {
    getDataManager(cart?.getCartItems).then((x) => {
      if (x?.status === "success") {
        dispatch(setCartItems(x?.data?.results));
      } else {
        message.error({
          content: "Error ocurred while fetching cart items",
        });
      }
    });
  };

  const fetchWishlist = () => {
    getDataManager(wishlistAPI?.getWishlist).then((x) => {
      if (x?.status === "success") {
        dispatch(setWishlistItems(x?.data?.results));
      } else {
        message.error({
          content: "Error ocurred while loading wishlist items",
        });
      }
    });
  };

  const onCloseLoginModal = () => {
    dispatch(setShowLoginModal(false));
  };

  return (
    <div id="base-component" className="base-component">
      {loading && <Spin title="Loading Products..." />}
      <div className="sticky-header">
        <Header
          setShowCartDrawer={() => {
            if (isLoggedIn) {
              setShowCartDrawer(true);
            } else {
              dispatch(setShowLoginModal(true));
            }
          }}
        />
        <MobileHeader
          showMenuSideDrawer={() => setShowMenuDrawer(true)}
          setShowCartDrawer={() => {
            if (isLoggedIn) {
              setShowCartDrawer(true);
            } else {
              dispatch(setShowLoginModal(true));
            }
          }}
        />
        {!showMenuDrawer && <CommonMenu />}
      </div>
      {isHome && <Sliders />}
      <Home
        showCartDrawer={showCartDrawer}
        setShowCartDrawer={setShowCartDrawer}
        openLoginModal={() => dispatch(setShowLoginModal(true))}
      />
      <MenuSideBar
        showSidebar={showMenuDrawer}
        onCloseSidebar={() => setShowMenuDrawer(false)}
      />
      <Login showLogin={showLoginModal} onClose={onCloseLoginModal} />
      <Footer />
    </div>
  );
};

export default App;
