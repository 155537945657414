import { Space } from "antd";
import React from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

const SearchResults = ({ hideSearchResults, clearSearch, products }) => {
  let slicedProducts = products;
  slicedProducts.splice(10);

  const ele = document.getElementById("tajira-search-results");
  window.addEventListener("click", function (e) {
    if (ele && !ele.contains(e.target)) {
      hideSearchResults();
      window.removeEventListener("click", null);
    }
  });

  return (
    <div id="tajira-search-results" className="tajira-search-results">
      <Space direction="vertical" size={10} align="start">
        {(slicedProducts || []).map((product, index) => {
          return (
            <Link
              key={index}
              className="product-row"
              to={`/product/${product?.slug}`}
              onClick={() => {
                clearSearch();
                hideSearchResults();
              }}
            >
              <img src={product.image} alt="" className="prod-image" />
              <section className="prod-description">
                <div className="title">{product.name}</div>
                <div className="price">
                  AED {Number(product?.new_price).toFixed(2)}
                </div>
              </section>
            </Link>
          );
        })}
      </Space>
      {/* <div className="search-results-see-more">See All Result (12)</div> */}
    </div>
  );
};

export default SearchResults;
