import React from "react";
import {
  HeartOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  LoginOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Badge, Popover } from "antd";
import TopMenuBar from "./mini-components/top-menu-bar";
import GlobalSearch from "./mini-components/global-search";

import {
  setShowLoginModal,
  setIsLoggedIn,
  clearRedux,
} from "../../utils/redux/actions/actions";

import comanyLogo from "../../images/companyLogo.PNG";
import emailForward from "../../images/email-forward.png";

import "./styles.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Header = (props) => {
  return (
    <header className="header-base-container">
      <TopMenuBar />
      <section className="header-main-contents">
        <Logo />
        <FreeQuote />
        <GlobalSearch />
        <WishList />
        <Cart {...props} />
        <MyAccount />
      </section>
    </header>
  );
};

export default Header;

const Logo = () => {
  return (
    <Link to="/">
      <img className="company-logo" src={comanyLogo} alt="logo" />
    </Link>
  );
};

const FreeQuote = () => {
  return (
    <Link className="free-quote" to="/contact-us">
      <img src={emailForward} alt="" />
      <div className="free-quote--text">
        <span className="light-grey-text">For Any Query</span>

        <span className="blue-text">support@tajira.com</span>
      </div>
    </Link>
  );
};

const WishList = () => {
  const { wishlistItems, isLoggedIn } = useSelector((state) => state.app);

  let quantity = 0;

  (wishlistItems || []).forEach((w) => {
    quantity = quantity + w?.products?.length;
  });

  return (
    <Link className="wishlist" to="/account/wishlist">
      {isLoggedIn ? (
        <Badge count={quantity} showZero={true}>
          <HeartOutlined />
        </Badge>
      ) : (
        <HeartOutlined />
      )}
      &nbsp;
      <span className="wishlist-text">Wishlist</span>
    </Link>
  );
};

const Cart = () => {
  const { cartItems, isLoggedIn } = useSelector((state) => state.app);

  let quantity = 0;

  (cartItems || []).forEach((w) => {
    quantity = quantity + w?.products?.length;
  });
  return (
    <Link to="/shopping-cart">
      <section className="cart">
        {isLoggedIn ? (
          <Badge count={quantity} showZero={true}>
            <ShoppingCartOutlined />
          </Badge>
        ) : (
          <ShoppingCartOutlined />
        )}
        &nbsp;
        <span className="cart-text">My Cart</span>
      </section>
    </Link>
  );
};

const MyAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userData = JSON.parse(
    localStorage.getItem("tajira-website-logged-in-user-data")
  );

  const handleLogout = () => {
    localStorage.removeItem("tajira-website-logged-in-user-data");
    dispatch(setIsLoggedIn(false));
    dispatch(clearRedux());
    history.push("/");
  };

  const handleViewAccount = () => {
    history.push("/account/my-account");
  };

  const handleViewMessages = () => {
    history.push("/all-messages");
  };

  const userPopover = () => {
    return (
      <section className="user-inner-container">
        <div className="logout-opt" onClick={handleViewAccount}>
          <UserOutlined />
          Account
        </div>
        <div className="logout-opt" onClick={handleViewMessages}>
          <MessageOutlined />
          Messages
        </div>
        <div className="logout-opt" onClick={handleLogout}>
          <LoginOutlined />
          Logout
        </div>
      </section>
    );
  };

  return userData ? (
    <Popover
      placement="bottom"
      content={userPopover()}
      trigger="hover"
      className="user-popover"
    >
      <section>
        <div className="login-btn">Account</div>
        <div className="unsigned-options">
          <span>
            {userData?.first_name} {userData?.last_name}
          </span>
        </div>
      </section>
    </Popover>
  ) : (
    <div className="my-account">
      <UserOutlined />{" "}
      <section>
        <div className="login-btn">Account</div>
        <div className="unsigned-options">
          <Link to="/register">
            <span>Register</span>
          </Link>{" "}
          or{" "}
          <span onClick={() => dispatch(setShowLoginModal(true))}>Sign in</span>
        </div>
      </section>
    </div>
  );
};
