import { handleActions } from "redux-actions";
import produce from "immer";

import * as actions from "../actions/actions";

const initialState = {
  userData: {
    id: 3,
  },
  showLoginModal: false,
  isLoggedIn: false,
  cartItems: [],
  wishlistItems: [],
  checkoutCartItems: [],
  newArrivalData: [],
  topSellData: [],
  brands: [],
  sliders: [],
  categories: [],
  featuredData: [],
  notes: [],
};

export default handleActions(
  {
    [actions.setUserData](state, { payload }) {
      return produce(state, (state) => {
        state.userData = payload;
      });
    },
    [actions.setNotes](state, { payload }) {
      return produce(state, (state) => {
        state.notes = payload;
      });
    },
    [actions.setShowLoginModal](state, { payload }) {
      return produce(state, (state) => {
        state.showLoginModal = payload;
      });
    },
    [actions.setIsLoggedIn](state, { payload }) {
      return produce(state, (state) => {
        state.isLoggedIn = payload;
      });
    },
    [actions.clearRedux](state, { payload }) {
      return produce(state, (state) => {
        state.cartQuantity = 0;
        state.wishListQuantity = 0;
        state.userData = {};
      });
    },
    [actions.setCartItems](state, { payload }) {
      return produce(state, (state) => {
        state.cartItems = payload;
      });
    },

    [actions.setWishlistItems](state, { payload }) {
      return produce(state, (state) => {
        state.wishlistItems = payload;
      });
    },

    [actions.setCheckoutCartItems](state, { payload }) {
      return produce(state, (state) => {
        state.checkoutCartItems = payload;
      });
    },
    [actions.setInfoPage](state, { payload }) {
      return produce(state, (state) => {
        state.infoPage = payload;
      });
    },
    [actions.setNewArrivalData](state, { payload }) {
      return produce(state, (state) => {
        state.newArrivalData = payload;
      });
    },
    [actions.setTopSellData](state, { payload }) {
      return produce(state, (state) => {
        state.topSellData = payload;
      });
    },
    [actions.setFeaturedData](state, { payload }) {
      return produce(state, (state) => {
        state.featuredData = payload;
      });
    },
    [actions.setBrands](state, { payload }) {
      return produce(state, (state) => {
        state.brands = payload;
      });
    },
    [actions.setSliders](state, { payload }) {
      return produce(state, (state) => {
        state.sliders = payload;
      });
    },
    [actions.setCategories](state, { payload }) {
      return produce(state, (state) => {
        state.categories = payload;
      });
    },
  },
  initialState
);
