import React from "react";
import { Button as AntButton } from "antd";

import "./styles.scss";

const Button = ({ onClick, children, text, style, ...otherProps }) => {
  return (
    <AntButton
      onClick={onClick}
      className="tajira-base-button"
      style={style}
      {...otherProps}
    >
      {text ? text : children}
    </AntButton>
  );
};

export default Button;
