import React, { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";

import { Input, message } from "antd";
import { useHistory } from "react-router-dom";

import SearchResults from "./search-results";

import API from "../../../../utils/api";
import { getDataManager } from "../../../../utils/helper.functions";

import "./styles.scss";

const GlobalSearch = () => {
  const product = new API.Products();

  const [searchString, setSearchString] = useState("");
  const [searching, setSearching] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [products, setProducts] = useState([]);
  const [timer, setTimer] = useState(null);
  const [stopSearch, setStopSearch] = useState(false);

  const history = useHistory();

  const handleSearch = (value) => {
    setStopSearch(false);
    setSearchString(value);
    clearTimeout(timer);
    setTimer(
      setTimeout(() => {
        if (value !== "" && !searching) {
          fetchProductsList(value);
        }
      }, 500)
    );
  };

  const handleSearchBtnClick = () => {
    if (searchString) {
      setSearchString("");
      setStopSearch(true);
      message.loading({ key: "loader" });
      history.push({ pathname: "/search-results", search: searchString });
    }
  };

  const handleEnterPress = (e) => {
    if (e.keyCode === 13) {
      handleSearchBtnClick();
    }
  };

  const fetchProductsList = (value) => {
    message.loading({ content: "Searching...", duration: 0, key: "loader" });
    getDataManager(product?.getProducts, setSearching, `?name=${value}`).then(
      (x) => {
        if (x?.data?.results.length > 0) {
          message.success({
            content: "Products found",
            duration: 3,
            key: "loader",
          });
          if (!stopSearch) {
            setShowResults(true);
            setProducts(x?.data?.results);
          }
        } else {
          setSearchString("");
          setShowResults(false);
          message.error({
            content: "No product found!",
            duration: 3,
            key: "loader",
          });
        }
      }
    );
  };

  return (
    <div className="global-search-base-container">
      <Input
        placeholder="search your products"
        onChange={(e) => {
          setSearchString(e.target.value);
          handleSearch(e.target.value);
        }}
        size="large"
        value={searchString}
        onKeyDown={handleEnterPress}
      />
      {!stopSearch && showResults && (
        <SearchResults
          hideSearchResults={() => setShowResults(false)}
          clearSearch={() => setSearchString("")}
          products={products}
        />
      )}
      <button className="global-search--btn" onClick={handleSearchBtnClick}>
        <SearchOutlined />
      </button>
    </div>
  );
};

export default GlobalSearch;
