/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Space } from "antd";
import { useSelector } from "react-redux";
import { AlignLeftOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

import CategoryCascadedOptions from "./category-cascaded-options";
import BrandsCascadedOptions from "./brands-cascaded-options";

import mobileIcon from "../../images/mobile.png";
import appStoreIcon from "../../images/appstore.png";
import googleStoreIcon from "../../images/googleplay.png";
import API from "../../utils/api";

import "./styles.scss";

const CommonMenu = () => {
  const history = useHistory();
  const { infoPage } = useSelector((state) => {
    return state?.app;
  });

  const [activeOption, setActiveOption] = useState("home");

  const handlePageChange = (key, path) => {
    setActiveOption(key);
    history.push(path);
  };

  return (
    <>
      <section className="common-menu-base-container">
        <section className="common-menu--left-content">
          <div
            className="menu-option category-option"
            onClick={() => setActiveOption(1)}
          >
            <AlignLeftOutlined />
            ALL CATEGORIES
            <CaretDownOutlined />
            <CategoryCascadedOptions />
          </div>
          <div
            className="menu-option brand-option"
            onClick={() => setActiveOption(2)}
          >
            Brands <CaretDownOutlined />
            <BrandsCascadedOptions />
          </div>
          <div
            className="menu-option"
            onClick={() => handlePageChange(3, "/all-shops")}
          >
            Shops
          </div>
          <div
            className="menu-option"
            onClick={() => handlePageChange(4, "/blogs")}
          >
            Blogs
          </div>
          <div
            className="menu-option"
            onClick={() => handlePageChange(4, "/campaigns")}
          >
            Campaigns
          </div>
          <div
            className="menu-option"
            onClick={() => handlePageChange(6, "/contact-us")}
          >
            Contact Us
          </div>
          <div
            className="menu-option"
            onClick={() => handlePageChange(7, "/about-us")}
          >
            About Us
          </div>
        </section>
        <section className="common-menu--right-content">
          <div className="menu-option">
            <Space size={10}>
              <img height={20} src={mobileIcon} alt="" /> Download the app to
              shop big!
            </Space>
          </div>
          <div className="menu-option">
            <a href={infoPage?.ios_app} target="_blank" rel="noreferrer">
              <img height={25} src={appStoreIcon} alt="" />
            </a>
          </div>
          <a
            href={infoPage?.android_app}
            target="_blank"
            rel="noreferrer"
            className="menu-option"
          >
            <img height={25} src={googleStoreIcon} alt="" />
          </a>
        </section>
      </section>
    </>
  );
};

export default CommonMenu;
