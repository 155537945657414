import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import ArabToolsCarousel from "../../common/carousel";

import sliderNext from "../../../images/slider-next.png";
import sliderPrev from "../../../images/slider-prev.png";

import "./styles.scss";

const Banner = () => {
  const [carouselRef, setCarouselRef] = useState({});

  const getRefsFromChild = (childRefs) => {
    setCarouselRef(childRefs);
  };

  const { sliders } = useSelector((state) => {
    return state?.app;
  });

  const onPrev = () => carouselRef.slidePrev();

  const onNext = () => carouselRef.slideNext();

  const configs = [
    { width: 400, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 768, itemsToShow: 1, itemsToScroll: 1 },
    { width: 1200, itemsToShow: 1, itemsToScroll: 1 },
    { width: 1450, itemsToShow: 1 },
  ];

  return (
    <div className="tajira-banner">
      <img className="prev-btn" src={sliderPrev} alt="" onClick={onPrev} />
      <ArabToolsCarousel configs={configs} getRefsFromChild={getRefsFromChild}>
        {(sliders || []).map((s) => (
          <Link
            className="banner-img-container"
            to={(location) => ({ ...location, pathname: s?.link })}
            target="_blank"
            key={s?.id}
          >
            <img
              className="banner-img"
              height="200px"
              src={s?.image_url}
              alt=""
            />
            {s.button_text && (
              <div className="tajira-base-button">{s.button_text}</div>
            )}
          </Link>
        ))}
      </ArabToolsCarousel>
      <img className="next-btn" src={sliderNext} alt="" onClick={onNext} />
    </div>
  );
};

export default Banner;
