import { createAction } from "redux-actions";

export const setUserData = createAction(
  "SET_USER_DATA",
  (userData) => userData
);

export const setCartItems = createAction(
  "SET_CART_ITEMS",
  (payload) => payload
);

export const setWishlistItems = createAction(
  "SET_WISHLIST_ITEMS",
  (payload) => payload
);

export const setShowLoginModal = createAction(
  "SET_SHOW_LOGIN_MODAL",
  (showLoginModal) => showLoginModal
);

export const setInfoPage = createAction(
  "SET_INFO_PAGE",
  (infoPage) => infoPage
);

export const setCheckoutCartItems = createAction(
  "SET_CHECKOUT_CART_ITEMS",
  (payload) => payload
);

export const setIsLoggedIn = createAction(
  "SET_IS_LOGGED_IN",
  (isLoggedIn) => isLoggedIn
);

export const clearRedux = createAction(
  "CLEAR_REDUX",
  (isLoggedIn) => isLoggedIn
);

export const setNewArrivalData = createAction(
  "SET_NEW_ARRIVAL_DATA",
  (newArrivalData) => newArrivalData
);

export const setTopSellData = createAction(
  "SET_TOP_SELL_DATA",
  (topSellData) => topSellData
);

export const setFeaturedData = createAction(
  "SET_FEATURED_DATA",
  (featuredData) => featuredData
);

export const setNotes = createAction("SET_NOTES", (notes) => notes);

export const setBrands = createAction("SET_BRANDS", (brands) => brands);

export const setSliders = createAction("SET_SLIDERS", (sliders) => sliders);

export const setCategories = createAction(
  "SET_CATEGORIES",
  (categories) => categories
);
