/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import Carousel from "react-elastic-carousel";

import { useWindowSize } from "../../../utils/custom-hooks";

import { containerSizes } from "./configs";

import "./style.scss";

const ArabToolsCarousel = ({ children, getRefsFromChild, configs, width }) => {
  let carouselRef = useRef();

  const windowSize = useWindowSize();

  useEffect(() => {
    getRefsFromChild && getRefsFromChild(carouselRef);
  }, []);

  const breakPoints = (containerSizes || []).map((size) => ({
    width: size,
    itemsToShow:
      windowSize?.width > 600
        ? Math.floor(size / width)
        : Math.round(size / width),
    itemsToScroll:
      windowSize?.width > 600
        ? Math.floor(size / width)
        : Math.round(size / width),
  }));

  // const breakPoints = [
  //   { width: 400, itemsToShow: 2, itemsToScroll: 1 },
  //   { width: 450, itemsToShow: 3, itemsToScroll: 1 },
  //   { width: 500, itemsToShow: 3, itemsToScroll: 1 },
  //   { width: 600, itemsToShow: 3, itemsToScroll: 1 },
  //   { width: 800, itemsToShow: 4, itemsToScroll: 1 },
  //   { width: 1200, itemsToShow: 6, itemsToScroll: 3 },
  //   { width: 1450, itemsToShow: 8 },
  // ];

  return (
    <div className="carousel-header">
      <Carousel
        breakPoints={configs ? configs : breakPoints}
        enableSwipe={true}
        showArrows={false}
        pagination={false}
        ref={(ref) => (carouselRef = ref)}
        enableMouseSwipe={true}
        // enableAutoPlay={true}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default ArabToolsCarousel;
