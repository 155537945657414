/* eslint-disable no-useless-concat */
import { React, useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "./styles.scss";

const { SubMenu } = Menu;

const SubOptions = () => {
  const { categories } = useSelector((state) => state.app);
  const [selectedKey, setSelectedKey] = useState("");

  return (
    <div
      id="cascaded-options-base-container"
      className="cascaded-options-base-container"
    >
      <Menu
        mode="vertical"
        style={{ maxHeight: "500px", overflow: "auto" }}
        onOpenChange={(key) => setSelectedKey(key)}
        selectedKeys={[selectedKey]}
      >
        {categories?.map((c) => (
          <SubMenu
            key={c?.id}
            title={
              <Link to={`/category/${c?.id}`}>
                <div>{c?.name}</div>
              </Link>
            }
          >
            {c?.children.map((ch) => (
              <Menu.Item key={ch?.id}>
                <Link to={`/category/${ch?.id}`}>
                  <div>{ch?.name}</div>
                </Link>
              </Menu.Item>
            ))}
          </SubMenu>
        ))}
      </Menu>
    </div>
  );
};

export default SubOptions;
